<template>
  <div>
    <Breadcrumb :items="items" />
    <Loader v-if="loading && editMode"></Loader>
    <div v-else>
      <noData v-if="noData" />
      <div class="form countryForm custom_card" v-else>
        <ValidationObserver ref="formAbout" @submit.prevent="submit">
          <div class="row">
            <!-- Image Ar -->
            <div class="d-flex gap-3 flex-wrap">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.profileImage')"
                class="d-block my-auto"
                style="width: 100%"
                :rules="editMode ? '' : 'required'"
              >
                <!-- @change="sentImage($event)" -->
                <v-file-input
                  :disabled="btnLoading"
                  :loading="btnLoading"
                  @change="uploadImage($event, 'abouts', 'avatar')"
                  v-model="data.avatar.mainMedia"
                  class="d-none"
                  :name="$t('labels.profileImage')"
                  accept="image/*,|size:2048"
                  label=""
                  id="banner-create-image-main"
                ></v-file-input>
                <label
                  for="banner-create-image-main"
                  class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
                >
                  <v-img
                    v-if="data.avatar.preview"
                    class="d-flex"
                    cover
                    :src="data.avatar.preview"
                    :aspect-ratio="3.4"
                  />
                  <template v-else>
                    <img
                      class="d-flex img-fluid mx-auto"
                      src="@/assets/media/images/placeholder.png"
                    />
                    <!-- <i class="fal fa-upload"></i>
                <span>{{ $t(`labels.browse`) }}</span> -->
                  </template>

                  <span class="error--text d-block">
                    {{ errors[0] }}
                  </span>
                </label>
              </validation-provider>
            </div>
            <!-- Titles -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.title') + $t('inArabic')"
              class="col-lg-6"
              rules="required|min:2|max:250"
            >
              <base-input
                @keypress="isArabic"
                :name="$t('labels.title') + $t('inArabic')"
                type="text"
                :placeholder="$t('labels.title') + $t('inArabic')"
                v-model="data.ar.title"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.title') + $t('inEnglish')"
              class="col-lg-6"
              rules="required|min:2|max:250"
            >
              <base-input
                @keypress="isEnglish"
                :name="$t('labels.title') + $t('inEnglish')"
                type="text"
                :placeholder="$t('labels.title') + $t('inEnglish')"
                v-model="data.en.title"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- Decs -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.desc') + $t('inArabic')"
              class="col-lg-6 m-auto"
              rules="required|min:2"
            >
              <base-input
                :name="$t('labels.desc') + $t('inArabic')"
                type="ckeditor"
                :placeholder="$t('labels.desc') + $t('inArabic')"
                v-model="data.ar.desc"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.desc') + $t('inEnglish')"
              class="col-lg-6"
              rules="required|min:2"
            >
              <base-input
                :name="$t('labels.desc') + $t('inEnglish')"
                type="ckeditor"
                :placeholder="$t('labels.desc') + $t('inEnglish')"
                v-model="data.en.desc"
              />
              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- Start:: Ordering -->
            <!-- <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.ordering')"
              class="col-lg-6"
              rules="required|min_value:0"
            >
              <base-input
                :name="$t('labels.ordering')"
                type="number"
                @keypress="preventMinus"
                :placeholder="$t('labels.ordering')"
                v-model="data.ordering"
              />

              <span v-if="errors[0]" class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider> -->

            <!-- End:: Ordering -->
            <!-- Start:: user_type -->
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('labels.user_type')"
              class="col-lg-6"
              rules="required"
            >
              <base-select-input
                v-model="data.user_type"
                getUrl="continents"
                :static="true"
                :name="$t('labels.user_type')"
                :staticItems="userTypes"
                :placeholder="$t('labels.user_type')"
              ></base-select-input>

              <span class="error--text d-inline-block">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <!-- End:: user_type -->
          </div>
          <!-- Start :: Checks -->
          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>{{ $t("labels.status") }}</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>

          <!-- End :: Checks -->

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.is_active')"
            class="col-md-6"
            rules="required"
          >
            <div>
              <div class="input-title">
                <h4>{{ $t("labels.active") }}</h4>
              </div>
              <v-checkbox
                :name="$t('labels.is_active')"
                class="py-0 px-0"
                v-model="data.is_active"
                :label="$t('labels.is_active')"
              ></v-checkbox>
            </div>
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <div class="button_wrapper center">
            <v-btn
              type="submit"
              @click="submit"
              :disabled="loading"
              :loading="loading"
            >
              {{ $t("submit") }}
            </v-btn>
          </div>
          <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// import MapWrite from "../../../../components/Maps/MapWrite.vue";

export default {
  // components: { MapWrite },
  props: ["id"],

  data() {
    return {
      noData: false,
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.about.title"),
          disabled: false,
          href: "/about/show-all",
        },
        {
          text: this.$t(`breadcrumb.about.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      // Loading

      loading: false,

      showArea: false,
      btnLoading: false,

      data: {
        avatar: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        ar: {
          title: null,
          desc: null,
          slug: null,
        },
        en: {
          title: null,
          desc: null,
          slug: null,
        },
        ordering: null,
        user_type: null,
        is_active: false,
      },
      userTypes: [
        {
          id: "all",
          name: this.$t("all"),
        },
        {
          id: "client",
          name: this.$t("labels.client"),
        },
        {
          id: "coach",
          name: this.$t("labels.coach"),
        },
      ],
    };
  },

  methods: {
    ...mapMutations({
      set_breadcrumb: "set_breadcrumb",
    }),

    // === Images
    imageArUploded(link) {
      this.data.image_ar.title = link;
    },
    imageEnUploded(link) {
      this.data.image_en.title = link;
    },

    getEditData() {
      if (this.editMode) {
        this.noData = false;
        this.loading = true;
        this.axios({
          method: "GET",
          url: `about/${this.id}`,
        })
          .then((res) => {
            this.loading = false;
            this.data = {
              avatar: {
                preview: res.data.data.image.media,
                mainMedia: null,
                image: null,
                mainPreviewUrl: null,
                id: res.data.data.image.id,
              },

              ar: {
                title: res.data.data.ar.title,
                desc: res.data.data.ar.desc,
              },
              en: {
                title: res.data.data.en.title,
                desc: res.data.data.en.desc,
              },
              user_type: this.userTypes.find(
                (type) => type.id == res.data.data.for
              ),
              is_active: res.data.data.is_active,
            };
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("error"),
              message:
                err.response?.data.message ?? err.response?.data.messages,
            });
            this.noData = true;
            this.loading = false;
          });
      } else {
        this.showArea = true;
      }
    },

    submit() {
      this.$refs.formAbout.validate().then((d) => {
        if (d) {
          this.loading = true;
          const data = new FormData();

          if (this.data.avatar.image) {
            data.append("image[media]", this.data.avatar?.image);
            if (this.id) {
              data.append("image[id]", this.data.avatar?.id);
            }
          }

          data.append("ar[title]", this.data.ar.title);
          data.append("en[title]", this.data.en.title);

          data.append("ar[desc]", this.data.ar.desc);
          data.append("en[desc]", this.data.en.desc);
          data.append("for", this.data.user_type?.id);
          data.append("is_active", +this.data.is_active);

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `about/${this.id}`;
          } else {
            url = "about";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");

          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.loading = false;
              this.$router.push("/about/show-all");
            })
            .catch((err) => {
              this.loading = false;
              this.$iziToast.error({
                title: this.$t("error"),
                message: err.response.data.message,
              });
            });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
    sentImage_ar(event) {
      if (event) {
        this.data.image_ar.preview = URL.createObjectURL(event);
        this.data.image_ar.image = event;
      }
    },
    sentImage_en(event) {
      if (event) {
        this.data.image_en.preview = URL.createObjectURL(event);
        this.data.image_en.image = event;
      }
    },
    uploadImage(event, modal, varName) {
      if (event) {
        this.btnLoading = true;
        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", modal);

        this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASE_URL_GENERAL}attachments`,
          data: data,
        })
          .then((res) => {
            this.data[varName].image = res.data.data;
            this.data[varName].preview = URL.createObjectURL(event);
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },
  },

  computed: {
    editMode() {
      return !!this.id;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    if (this.id) {
      this.getEditData();
    }
  },
};
</script>
