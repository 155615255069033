export default class Permission {
  setControls(path) {
    let status;
    if (localStorage.getItem("myTeam_Dash_User_Type") != "super_admin") {
      if (localStorage.getItem("myTeam_controls")) {
        let controls = JSON.parse(localStorage.getItem("myTeam_controls"));

        const found = controls.find((control) => control.url == path);

        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }
    return status;
  }
  allowed(path) {
    let status;
    if (localStorage.getItem("myTeam_Dash_User_Type") != "superadmin") {
      if (localStorage.getItem("myTeam_dash_permissions")) {
        let controls = JSON.parse(
          localStorage.getItem("myTeam_dash_permissions")
        );
        const found = controls.find((control) => control.url == path);
        console.log(found);
        if (found) {
          status = false;
        } else {
          status = true;
        }
      }
    } else {
      status = false;
    }
    return status;
  }
}
